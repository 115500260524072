import {createApp} from 'vue';
//import App         from './views/Index.vue';
import App         from './App.vue';
import store       from './store';
import router      from './router';
import auth        from './plugins/auth.js';
import http        from './config/http';
import dateTime        from './config/dateTime';

// Create App instance
const app = createApp(App);

// Use App modules
app
.use(http)
.use(store)
.use(router)
.use(auth)
.use(dateTime)
.mount('#app');
