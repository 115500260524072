import {createRouter, createWebHistory} from 'vue-router';

function loadView(view) {
    return () => import(`../views/${view}.vue`);
}

const router = createRouter({
    hashbang: false,
    history: createWebHistory(),
    routes: [
        // Site routes
        {
            path: '/',
            name: 'site-home',
            component: loadView('site/Home'),
            meta: {
                auth: undefined
            }
        },
        {
            path: '/jumbotron',
            name: 'site-jumbotron',
            component: loadView('site/Jumbotron'),
            meta: {
                auth: undefined
            }
        },
        {
            path: '/about',
            name: 'site-about',
            component: loadView('site/About'),
            meta: {
                auth: undefined
            }
        },
        {
            path: '/dashboard',
            name: 'site-dashboard',
            component: loadView('site/Dashboard'),
            meta: {
                auth: undefined
            }
        },
        {
            path: '/album',
            name: 'site-album',
            component: loadView('site/Album'),
            meta: {
                auth: undefined
            }
        },
        {
            path: '/carousel',
            name: 'site-carousel',
            component: loadView('site/Carousel'),
            meta: {
                auth: undefined
            }
        },

        // Authentification routes
        // ----------------------------
        {
            path: '/login',
            name: 'auth-login',
            component: loadView('auth/Login'),
            meta: {
                auth: false
            }
        },
        {
            path: '/register',
            name: 'auth-register',
            component: loadView('auth/Register'),
            meta: {
                auth: false
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: loadView('auth/ForgotPassword'),
            meta: {
                auth: false
            }
        },
        {
            path: '/reset-password/:token',
            name: 'reset-password-form',
            component: loadView('auth/ResetPasswordForm'),
            meta: {
                auth:false
            }
        },
    /*
        {
            path: '/api/auth/email/verify/:id/:token',
            name: 'verify-email-form',
            component: loadView('auth/VerifyEmail'),
            meta: {
                auth:false
            }
        },
    */
        {
            path: '/logout',
            name: 'auth-logout',
            component: loadView('auth/Logout'),
            meta: {
                auth: true
            }
        },

        // Logged user's pages
        // ----------------------------
        {
            path: '/user',
            component: loadView('user/Index'),
            meta: {
                auth: true
            },
            children: [
                {
                    path: 'logged',
                    name: 'logged-user',
                    component: loadView('user/Logged')
                },
            /*
                {
                    path: '/',
                    name: 'user-landing',
                    redirect: {
                        name: 'user-account'
                    }
                },
                {
                    path: 'account',
                    name: 'user-account',
                    component: loadView('user/Account')
                },
                {
                    path: 'unimpersonate',
                    name: 'user-unimpersonate',
                    component: loadView('user/Unimpersonate')
                },
                {
                    path: 'users',
                    name: 'user-users',
                    component: loadView('user/Users')
                },
            */
                {
                    path: 'logout',
                    name: 'user-logout',
                    //component: loadView('user/Logout')
                    component: loadView('auth/Logout')
                }
            ]
        },


        // Logged master's pages
        // ----------------------------
        {
            path: '/master',
            component: loadView('master/Index'),
            meta: {
                //auth: 'admin'
                auth: {
                    roles: [2, 3],
                    //roles: 'admin',
                    redirect: {name: 'auth-login'},
                    notFoundRedirect: {name: 'error-404'},
                /*
                    forbiddenRedirect: function (transition) {
                        return '/admin/403';
                    }
                */
                }
            },
            children: [
                {
                    path: '/',
                    name: 'master-landing',
                    redirect: {
                        name: 'master-customers'
                    }
                },
                {
                    path: 'customers',
                    name: 'master-customers',
                    component: loadView('master/Customers')
                },
                {
                    path: 'calendar',
                    name: 'master-calendar',
                    component: loadView('master/Calendar')
                },
                {
                    path: 'meals',
                    name: 'master-meals',
                    component: loadView('master/Meals')
                },
            /*
                {
                    path: 'users-store',
                    name: 'admin-users-store',
                    component: loadView('admin/UsersStore')
                }
            */
            ]

        },


        // Logged admin's pages
        // ----------------------------
        {
            path: '/admin',
            component: loadView('admin/Index'),
            meta: {
                //auth: 'admin'
                auth: {
                    roles: 3,
                    //roles: 'admin',
                    redirect: {name: 'auth-login'},
                    notFoundRedirect: {name: 'error-404'},
                /*
                    forbiddenRedirect: function (transition) {
                        return '/admin/403';
                    }
                */
                }
            },
            children: [
                {
                    path: '/',
                    name: 'admin-landing',
                    redirect: {
                        name: 'admin-users'
                    }
                },
                {
                    path: 'users',
                    name: 'admin-users',
                    component: loadView('admin/Users')
                },
            /*
                {
                    path: 'users-store',
                    name: 'admin-users-store',
                    component: loadView('admin/UsersStore')
                }
            */
            ]
        },




    ]
});

export default (app) => {
    app.router = router;

    app.use(router);
}
