<template>
    <div id="sidebarMenu" class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark collapse" style="width: 280px;">

        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <i class="bi bi-bootstrap-fill"></i>
            <span class="fs-4">Sidebar</span>
        </a>
        <hr>

        <div class="scrollarea mb-auto">

            <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span class="text-primary">Open site links</span>
            </h5>

            <ul class="nav nav-pills flex-column">

                <!--  ********************************** -->
                <!-- Links for the fixed site navigation -->
                <!--  ********************************** -->

                <li
                    v-for="(route, key) in state.routes.fixed"
                    v-bind:key="route.path"
                    class="nav-item"
                >
                    <a
                        class="nav-link text-white"
                        @click.prevent="goto(route)"
                        :key="key"
                        v-bind:href="route.path"
                        v-bind:class="{ active: route.isActive }"
                    >
                        <i
                            v-bind:class="route.icone"
                        ></i>
                        {{ route.name }}
                    </a>
                </li>
            </ul>

            <!--  ************************** -->
            <!--  Authenticated users routes -->
            <!--  ************************** -->
            <span v-if="$auth.check() && $auth.ready()">

                <hr>
                <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span class="text-primary">Logged user pages</span>
                </h5>

                <ul class="nav nav-pills flex-column">

                    <!--  ********************************** -->
                    <!-- Links for the fixed site navigation -->
                    <!--  ********************************** -->

                    <li
                        v-for="(route, key) in state.routes.logged"
                        v-bind:key="route.path"
                        class="nav-item"
                    >
                        <a
                            class="nav-link text-white"
                            @click.prevent="goto(route)"
                            :key="key"
                            v-bind:href="route.path"
                            v-bind:class="{ active: route.isActive }"
                        >
                            <i
                                v-bind:class="route.icone"
                            ></i>
                            {{ route.name }}
                        </a>
                    </li>
                </ul>
            </span>


            <!--  ************************** -->
            <!--  Authenticated master routes -->
            <!--  ************************** -->
            <span v-if="$auth.check(2) || $auth.check(3)">

                <hr>
                <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span class="text-primary">Master pages</span>
                </h5>

                <ul class="nav nav-pills flex-column">

                    <!--  ********************************** -->
                    <!-- Links for the fixed site navigation -->
                    <!--  ********************************** -->

                    <li
                        v-for="(route, key) in state.routes.master"
                        v-bind:key="route.path"
                        class="nav-item"
                    >
                        <a
                            class="nav-link text-white"
                            @click.prevent="goto(route)"
                            :key="key"
                            v-bind:href="route.path"
                            v-bind:class="{ active: route.isActive }"
                        >
                            <i
                                v-bind:class="route.icone"
                            ></i>
                            {{ route.name }}
                        </a>
                    </li>
                </ul>
            </span>


            <!--  ************************** -->
            <!--  Authenticated admin routes -->
            <!--  ************************** -->
            <span v-if="$auth.check(3)">

                <hr>
                <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span class="text-primary">Admin pages</span>
                </h5>

                <ul class="nav nav-pills flex-column">

                    <!--  ********************************** -->
                    <!-- Links for the fixed site navigation -->
                    <!--  ********************************** -->

                    <li
                        v-for="(route, key) in state.routes.admin"
                        v-bind:key="route.path"
                        class="nav-item"
                    >
                        <a
                            class="nav-link text-white"
                            @click.prevent="goto(route)"
                            :key="key"
                            v-bind:href="route.path"
                            v-bind:class="{ active: route.isActive }"
                        >
                            <i
                                v-bind:class="route.icone"
                            ></i>
                            {{ route.name }}
                        </a>
                    </li>
                </ul>
            </span>


        </div>

        <hr>

        <div class="dropdown">

            <div v-if="$auth.check() && $auth.ready()">

                <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUserLogged" data-bs-toggle="dropdown" aria-expanded="false">

                    <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">

                    <strong>
                        {{ $auth.user().name }}
                    </strong>

                </a>

                <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUserLogged">
                    <li><a class="dropdown-item" href="#">New project...</a></li>
                    <li><a class="dropdown-item" href="#">Settings</a></li>
                    <li><a class="dropdown-item" href="#">Profile</a></li>
                    <li><hr class="dropdown-divider"></li>

                    <li>
                        <a class="dropdown-item" href="#" @click.prevent="logout()">
                            Sign out
                        </a>
                    </li>
                </ul>

            </div>

            <div v-else>
                <button @click.prevent="login()" type="button" class="btn btn-outline-light">Sign in</button>
            </div>

        </div>


    </div>
</template>

<script>
import { reactive } from 'vue'
import { onMounted } from 'vue'
//import { getCurrentInstance } from 'vue'
import { useAuth } from '@websanova/vue-auth/src/v3.js';
import { useRouter } from 'vue-router'
//import { useRoute } from 'vue-router'
//import { onBeforeRouteUpdate } from 'vue-router'

export default {

    name: 'NewSideBar',

    components: {
        //AppLink,
    },

    setup() {

        const auth = useAuth();
        const router = useRouter()
        //const route = useRoute()

        //const internalInstance = reactive( getCurrentInstance() )

        const state = reactive({

            routes : {

                // fixed routes to navigate in the app
                fixed: [
                    { name: 'Home', path: 'site-home', icone: 'bi bi-house-door', isActive: false },
                    //{ name: 'Jumbotron', path: 'site-jumbotron', icone: 'bi bi-image', isActive: false },
                    { name: 'About', path: 'site-about', icone: 'bi bi-question-square', isActive: false },
                    { name: 'Dashboard', path: 'site-dashboard', icone: 'bi bi-speedometer2', isActive: false },
                    { name: 'Album', path: 'site-album', icone: 'bi bi-journal-album', isActive: false },
                    { name: 'Carousel', path: 'site-carousel', icone: 'bi bi-signpost-split', isActive: false },
                ],

                // routes available only for unlogged user
                unlogged: [
                    { name: 'Login', path: 'auth-login' },
                    { name: 'Register', path: 'auth-register' },
                    //{ name: 'Logout', path: 'user-logout' },
                ],

                // Logged user
                logged: [
                    { name: 'Logged', path: 'logged-user', icone: 'bi bi-people', isActive: false  },
                    //{ name: 'Users store', path: 'admin-users-store', icone: 'bi bi-person-lines-fill' },
                ],

                // MASTER
                master: [
                    { name: 'Customers', path: 'master-customers', icone: 'bi bi-people', isActive: false  },
                    { name: 'Calendar', path: 'master-calendar', icone: 'bi bi-calendar3', isActive: false  },
                    { name: 'Meals', path: 'master-meals', icone: 'bi bi-vinyl-fill', isActive: false  },
                    //{ name: 'Users store', path: 'admin-users-store', icone: 'bi bi-person-lines-fill' },
                ],

                // ADMIN
                admin: [
                    { name: 'Users', path: 'admin-users', icone: 'bi bi-people', isActive: false  },
                    //{ name: 'Users store', path: 'admin-users-store', icone: 'bi bi-person-lines-fill' },
                ],
            }

        })

        onMounted(() => {
            //internalInstance.appContext.config.globalProperties.$moment.locale('en')
        /*
            onBeforeRouteUpdate(async (to, from) => {
                // only fetch the user if the id changed as maybe only the query or the hash changed
                console.log(to)
                console.log(from)
                console.log(route.name)
            })
        */
        })

/*
        function changeLang(val) {
            //console.log(val)
            //let lang = internalInstance.appContext.config.globalProperties.$moment.locale(val)
            //console.log(lang)
        }
*/

        function goto(arg) {

            // reset all active class before push route
            for (var prop in state.routes) {
                state.routes[prop].forEach( el => el.isActive = false )
            }

            router.push({ name: arg.path })

            arg.isActive = true
        }

        function logout() {
            auth.logout({
                redirect: {name : 'site-home'}
            })
        }

        function login() {
            router.push({ name: 'auth-login' })
        }

        return {
            //auth,
            state,
            goto,
            login,
            logout,
            //changeLang
        }
    },
}
</script>

<style>
    @import '../../assets/css/sidebars.css';
</style>
