<template>
    <main>
        <SideBar />
        <div class="col scrollarea">
            <!-- Router View -->
            <slot />
        </div>
    </main>
</template>

<script>
//import NavBar from '@/components/menus/NavBar.vue'
import SideBar from '@/components/menus/SideBar.vue'

export default {
    name: 'Layout',
    components: {
        //NavBar,
        SideBar
    }
}

</script>
